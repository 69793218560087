.search-input {
    padding-left: 4px;
} 
.search-list {
    z-index: 900;
    position: absolute;
    top:50px;
    left:-30px;
    width:300px;
    padding: 0 50px;

}

    .search-list a {
        color: var(--darkLinkClr)
    }
    .suggestions {
        margin: 0;
        padding: 0;
        position: relative;
    }
  .suggestions li {
    background-color: dodgerblue;
    list-style: none;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
    margin: 0;
    padding: 20px;
    transition: background 0.2s;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
  }
  