.content-wrap{
    display:grid;
    grid-row-gap: 30px; 
    grid-column-gap: 20px;
}
.page {
    margin: 5% 0%;
    position: relative;
    font-size: 0.9rem;
    overflow: hidden;
    filter: drop-shadow(0 0 5px #333);
}
.page-overlay {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3% 40% 3% 3%;
    color: var(--primaryClr);
    text-shadow: 0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black, -0.05em -0.05em black, -0.05em 0.05em black, 0.05em -0.05em black, 0.05em 0.05em black;
}

  .page p{
    line-height: 20px;
    font-size: 12px;
  }
.page h1 {
    font-size: 1.2rem;
    line-height: 28px;
    font-weight: 700;
    text-shadow: 2px 2px 0 #bcbcbc, 4px 4px 0 #9c9c9c;
}
hr{
 border:0.5px solid rgba(0, 0, 0, 0.068);
 margin-top:0;
}
.date {
    font-size: .8rem;
}
.card-wrap p{
    font-family: 'Segoe UI', 'Open Sans', sans-serif;
    color: var(--thirdClr);
    font-size:1rem;
}
.content-wrap img {
    width: 100%;
    height: auto;
    max-width: fit-content;
}
.ads-image{
    width:100%;
    height: auto;
}
.category-title{
    width: 80px;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
}

@media (min-width:640px) {
    .content-wrap{
        grid-template-columns:1fr 1fr;
        grid-template-rows:auto ;
      
    }
    .card-wrap:nth-child(1){
        /* border:2px solid pink; */
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 3;
        font-size:1.2rem;
        display:grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        grid-column-gap: 20px;
      
         
    }
    .card-wrap:nth-child(1) .card{
        /* align-self: center; */
    }
    .card-wrap:nth-child(1) img{
       min-height: auto;
       width:100%; 
    }
}
@media (min-width:960px) {

    .card-wrap:nth-child(1){

        grid-template-columns: 4fr 2fr;
       
         
    }
    .card-wrap:nth-child(1) .card{
        align-self: center;
    }
    .page p{
        line-height: 24px;
        font-size: 1rem;
      }
      .page h1{
        font-size: 2.65rem;
        line-height: 48px;
        font-weight: 700;
      }
    
}

@media (min-width:1286px) {
    .content-wrap{
        width: 100%;
        grid-template-columns: 320px 520px 320px;
        grid-template-rows:1fr 1fr 1fr;
        font-size:0.7rem;
        grid-gap:20px;
        
    }
    .content-wrap h2{
        margin: 0;
    }
    
    .card-wrap{
        display: grid; 
        grid-template-columns:200px auto;
        grid-template-rows: auto;   
        grid-row-gap:40px;
        /* border:1px dotted black; */
        
    }
    .pos{
        position: relative;
        width:180px;
        height: 150px;
        padding-right:20px;
    }
    .content-wrap img{
        width:180px;
        height: 120px;
        padding-right:20px;
        
        
    }
    .card-wrap:nth-child(1){
        /* border:2px solid pink; */
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 2;
        font-size:1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;   
        
    }
    .card-wrap:nth-child(1) img{
        width:360px;
        height: 240px;
        left:-40px;
        position: absolute;
        
    }
    .card-wrap:nth-child(1) .card{
        align-self:flex-start ;
    }
    
    
    .card-wrap:nth-child(2){
        /* border:2px solid rgb(141, 21, 41); */
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 3;
        grid-column-end: 4;
        font-size:1rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;   
    }
    .card-wrap:nth-child(2) img{
        width:360px;
        height: 240px;
        position: absolute;
    }
}









