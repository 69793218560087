.page-container{
    /* border:1px solid green; */
    padding-top: 20px;
    display:grid;
    grid-template-rows:auto;
    grid-gap:0;
    
}
.page-container h1{
    font-size: 3.5rem;
    word-wrap: break-word;
}


    .page-container a {
        color: var(--lightLinkClr);
    }
.figure {
    width: 104%;
    transform: translateX(-2%);
    height: auto;
    grid-row-start: 1;
    grid-row-end: 2;
}
.page-container h3 a{
    color:var(--lightLinkClr);
    text-transform: uppercase;
}
.page-container h4{
    color:var(--thirdClr);
    font-weight: 300;
    font-size:1.45rem;
    line-height: 28px;
    
}

.text-page p{
    font-size:1.35rem;
    line-height: 33px;
    margin-bottom: 2%;
    font-weight: 300;    
}

.side-bar{
    justify-self:center;
    
    
}
.fa{
    color:var(--primaryClr);
}

em, u{
    font-weight: 300;
    padding:10px
}
em::after{
    content: ' "';
}
em::before{
    content: '" ';
}
.text-page img{
    max-height: 100%;
    max-width: 100%;
}

.post-details {
    grid-row: auto;
    grid-column-start: 1;
    grid-column-end: 11;
    color: #2B88FF;
    text-shadow: 1px 1px 1px #00f;
}


.post-subtitle {
    grid-row: auto;
    grid-column-start: 1;
    grid-column-end: 11;
    color: #808080;
    text-align: center;
    text-shadow: 1px 1px 1px #2B88FF;
}

.category {
    color: #616161;
    background: #bababa;
    text-shadow: #e0e0e0 1px 1px 0;
    display: inline;
}

/* Glow */

.post-headline {
    padding: 2rem 0;
    font-size: 80px;
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #2300e6, 0 0 40px #2300e6, 0 0 50px #2300e6, 0 0 60px #2300e6, 0 0 70px #2300e6;
    }

    to {
        text-shadow: 0 0 20px #fff, 0 0 30px #4da6ff, 0 0 40px #4da6ff, 0 0 50px #4da6ff, 0 0 60px #4da6ff, 0 0 70px #4da6ff, 0 0 80px #4da6ff;
    }
}


/* Glow End */

@media(min-width:940px) {

    .page-container{
        
        grid-template-columns: repeat(10, 1fr);
        grid-row: auto;
        padding-top: 0px;
        align-items: center;
        grid-column-gap: 30px;
        grid-row-gap: 60px;
    }
    .figure{
        width:103%;
        height: auto;
        grid-column-start: 5;
        grid-column-end:11;
        grid-row-start: 1;
        grid-row-end: 2;
        transform:translateX(0%);
    }

    .page-wrap {
        padding: 0 10px 0 0;
        grid-column-start: 1;
        grid-column-end: 11;
        min-height: 320px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        height: 100%;
        text-align: center;
    }
    .text-page{
        /* border:1px solid yellow; */
        grid-column-start: 1;
        grid-column-end:11;
        grid-row-start: auto;
        grid-row-end: auto;
        padding: 0 20px;
    }
    
    .side-bar{
        grid-column-start: 1;
        grid-column-end:3;
        align-self: flex-start;
        padding-top:50%;
        /* border:1px solid green; */
    }
    .vote-container{
        position: relative;
    
    }
    .vote{
        font-size: 0.7rem;
        position: absolute;
        top:-12px;
        left:29px;
        

    }

}
@media(min-width:1340px) {

    .figure{
        width:110%;
    }

}

@media(min-width:1840px) {

    .figure{
        width:130%;
    }

}